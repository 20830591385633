import ProductReturn from '@assets/icons/ProductReturn';
import styles from '../styles/FreeDeliveryFastReturn.module.scss';

export const FreeDeliveryFastReturn = () => {
  return (
    <div className={styles['top-container']}>
      <ProductReturn />
      <div className={styles['container']}>
        <span>Szybki zwrot i darmowa dostawa!</span>
        <p>Vikingu, zadowolenie gwarantowane! Inaczej zwrot pieniędzy.</p>
      </div>
    </div>
  );
};
