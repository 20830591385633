const ProductReturn = () => {
  return (
    <svg
      fill="none"
      height="25"
      viewBox="0 0 24 25"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.52 12.5C23.52 18.8521 18.3521 24.02 12 24.02C5.6479 24.02 0.47998 18.8521 0.47998 12.5C0.47998 12.1021 0.80206 11.78 1.19998 11.78C1.5979 11.78 1.91998 12.1021 1.91998 12.5C1.91998 18.0582 6.44182 22.58 12 22.58C17.5581 22.58 22.08 18.0582 22.08 12.5C22.08 6.94184 17.5581 2.42 12 2.42C9.32086 2.42 6.83374 3.46136 4.9627 5.3H7.67998C8.0779 5.3 8.39998 5.62208 8.39998 6.02C8.39998 6.41792 8.0779 6.74 7.67998 6.74H3.35998C2.96206 6.74 2.63998 6.41792 2.63998 6.02V1.7C2.63998 1.30208 2.96206 0.98 3.35998 0.98C3.7579 0.98 4.07998 1.30208 4.07998 1.7V4.15616C6.20422 2.12864 8.99542 0.98 12 0.98C18.3521 0.98 23.52 6.14792 23.52 12.5ZM18.48 9.62V16.1C18.48 16.4002 18.2937 16.6688 18.0132 16.7744L12.2532 18.9344C12.1716 18.9646 12.0857 18.98 12 18.98C11.9143 18.98 11.8284 18.9646 11.7468 18.9344L5.98678 16.7744C5.70622 16.6688 5.51998 16.4002 5.51998 16.1V9.62C5.51998 9.31976 5.70622 9.0512 5.98678 8.9456L11.7468 6.7856C11.91 6.72512 12.09 6.72512 12.2529 6.7856L18.0129 8.9456C18.2937 9.0512 18.48 9.31976 18.48 9.62ZM8.2903 9.62L12 11.0108L15.7097 9.62L12 8.2292L8.2903 9.62ZM6.95998 15.6008L11.28 17.2208V12.2792L6.95998 10.6592V15.6008ZM17.04 15.6008V10.6592L12.72 12.2792V17.2208L17.04 15.6008Z"
        fill="#62748C"
      />
    </svg>
  );
};

export default ProductReturn;
